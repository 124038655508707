import * as Sentry from "@sentry/react";

if (import.meta.env.VITE_ENVIRONMENT !== "dev") {
  Sentry.init({
    dsn: "https://05572b4d7fa05fcf463568e26e06f5d0@o303248.ingest.us.sentry.io/4508042298851328",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    normalizeDepth: 10,
    environment: import.meta.env.VITE_ENVIRONMENT,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
